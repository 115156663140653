import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { mergeMap } from 'rxjs';
import { AuthURL } from './auth-url.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _router:Router,
    private httpService:HttpService
  ) { }

  getAuthToken(): string | null {
    return sessionStorage.getItem('token');
  }
  setAuthToken(token: string): void {
    sessionStorage.setItem('token', token);
  }
  public refreshToken() {
    const email = sessionStorage.getItem('email');
    const refreshToken = sessionStorage.getItem('refreshToken');
    const body = {
      email,
      refreshToken
    };
    return this.httpService.post(AuthURL.EndPoints.refreshToken, body).pipe(
      mergeMap((res: any): any => {
        sessionStorage.setItem('refreshToken', res['data'].refreshToken.token);
        sessionStorage.setItem('email', res['data'].idToken.payload['email']);
        sessionStorage.setItem('id', res['data'].idToken.payload['custom:id']);
        sessionStorage.setItem('username', res['data'].idToken.payload['name']);
        console.log('new token generated...', res['data'].idToken.jwtToken);
        return [res['data'].idToken.jwtToken];
      })
    );
  }

  public logout() {
    this._router.navigate(['/login']);
    sessionStorage.clear();
    localStorage.clear();
  }

  getProductList(){
    return this.httpService.get(AuthURL.EndPoints.products)
  }
}
